import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import { useApi } from "utils";
import { Auth, useAuth } from "./useAuth";
import { AUTHENTICATION_STATUS } from "../constants";
import { fetchPetConfig as fetchPetConfigApi } from "../api-calls/fetchPetConfig";
import { PetConfigType } from "../../types";
import { PetTypesEnum, PetGenderEnum } from "../types/pets";

const initData: PetConfigType = {
  loaded: false,
  breeds: [],
  lifeStages: [],
  petTypes: [],
  petSizes: [],
  petStatus: [],
  petRemovalReasons: [],
  petGender: [],
  petFoodTypes: [],
  //client side state
  petStatusByName: { Active: 1, Remembered: 2, Deleted: 3 },
  petRemovalReasonById: { 1: "Rehomed", 2: "Deceased" },
};
const PetConfigContext = createContext<PetConfigType>(initData);

export function PetConfigProvider({ children }: { children: React.ReactNode }) {
  const config = useProvidePetConfig();

  function isLoaded() {
    return config.loaded;
  }

  function getBreed(breedId: number | null) {
    return config.breeds.find((item) => item.petBreedId === breedId);
  }
  function getGender(genderId: PetGenderEnum | null) {
    return config.petGender.find((item) => item.petGenderId === genderId);
  }
  function getSize(petTypeId: PetTypesEnum | null, sizeId: number | null) {
    const size = config.petSizes.find(
      (item) =>
        item.petSizeId.toString() === sizeId?.toString() &&
        item.petTypeId === petTypeId
    );
    return size;
  }
  function getLifeStageName(id: number) {
    return config.lifeStages.find((item) => item.petLifeStageId === id)
      ?.petLifeStageName as string;
  }
  function getLifeStageDesc(id: number) {
    return config.lifeStages.find((item) => item.petLifeStageId === id)
      ?.petLifeStageDesc as string;
  }

  return (
    <PetConfigContext.Provider
      value={{
        ...config,
        isLoaded,
        getBreed,
        getGender,
        getSize,
        getLifeStageName,
        getLifeStageDesc,
      }}
    >
      {children}
    </PetConfigContext.Provider>
  );
}

export const usePetConfig = () => {
  return useContext<PetConfigType>(PetConfigContext);
};

function useProvidePetConfig() {
  const [petConfig, setPetConfig] = useState<PetConfigType>(initData);
  const abortControllerRef = useRef<AbortController | null>(null);
  const auth: Auth = useAuth();

  const { exec: fetchPetConfig } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return fetchPetConfigApi(auth, abortController.signal).then((data) => {
      if (data) {
        setPetConfig({ ...data, loaded: true });
      }
    });
  });

  useEffect(() => {
    abortControllerRef.current?.abort();
    if (auth.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      fetchPetConfig();
    }
    return () => {
      abortControllerRef.current?.abort();
    };
  }, [auth.status]);

  return petConfig;
}
