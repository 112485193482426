import React from "react";

export function Check(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      fill="#258039"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 3.33337C11.3 3.33337 3.83337 10.8 3.83337 20C3.83337 29.2 11.3 36.6667 20.5 36.6667C29.7 36.6667 37.1667 29.2 37.1667 20C37.1667 10.8 29.7 3.33337 20.5 3.33337ZM17.1667 28.3334L8.83337 20L11.1834 17.65L17.1667 23.6167L29.8167 10.9667L32.1667 13.3334L17.1667 28.3334Z"
      />
    </svg>
  );
}
