import { HillsProductType } from "../../../../../types";

// Utility function to normalize values
export const normalizedValue = (value: string | undefined): string => {
  if (!value || typeof value !== 'string') return '';
  // Normalize case and handle apostrophes consistently
  return value.toLowerCase().replace(/`|'|’/g, ""); // Remove all types of apostrophes
};

// Function to capitalize words
export const capitalizeWords = (value: string): string =>
  value
    .replace(/`|'|’/g, "")
    .toLowerCase()
    .replace(/\b\w/g, char => char.toUpperCase())
    .replace(/Hill’S/g, "Hill’s");

// Normalize string values
export const normalizeStringValue = (value: string | undefined): string => {
  if (!value || typeof value !== 'string') return '';
  return capitalizeWords(value.trim().replace(/\u00A0/g, ' '));
};

// Function to remove the first word if it starts with "hill"
const removeFirstIfHills = (brand: string | undefined): string => {
  if (!brand || typeof brand !== 'string') return '';
  const normalizedBrand = normalizedValue(brand);
  const brandParts = normalizedBrand.split(' ');
  if (brandParts.length > 1 && brandParts[0].toLowerCase().startsWith('hill')) {
    return brandParts.slice(1).join(' ');
  }
  return brand;
};

// Helper function to translate brand names
const translateBrandNameForFilter = (brand: string, t: (key: string) => string): string => {
  const brandWithoutHills = removeFirstIfHills(brand);
  const capitalizedBrand = capitalizeWords(brandWithoutHills);
  const translatedBrand = t(`lifestage.${capitalizedBrand}`);
  return translatedBrand;
};

// Function to apply filters to products
export const applyFilters = (products: HillsProductType[], selectedFilters: Record<string, string[]>, t: (key: string) => string) => {
  return products.filter((product) => {
    return Object.entries(selectedFilters).every(([category, filters]) => {
      if (filters.length === 0) return true;

      const normalizedFilters = filters.map(normalizedValue);

      if (category === 'filterBrand') {
        const translatedProductBrand = translateBrandNameForFilter(product.filterBrand, t);
        const normalizedProductBrand = normalizedValue(translatedProductBrand);
        return normalizedFilters.includes(normalizedProductBrand);
      }
      if (category === 'healthCategory') {
        const normalizedHealthCategories = product.healthCategory?.map(normalizedValue);
        return normalizedFilters.some((filter) => normalizedHealthCategories?.includes(filter));
      }
      const productValue = product.tagsMap?.[category as keyof typeof product.tagsMap];
      if (Array.isArray(productValue)) {
        const normalizedProductValues = productValue.map(normalizedValue);
        return normalizedFilters.some((filter) => normalizedProductValues.includes(filter));
      }
      if (typeof productValue === 'string') {
        const normalizedProductValue = normalizedValue(productValue);
        return normalizedFilters.includes(normalizedProductValue);
      }
      return false;
    });
  });
};

// Extract unique top-level values from products
export const extractUniqueTopLevelValues = (products: HillsProductType[], key: keyof HillsProductType) => {
  const allValues = products.map((product) => product[key]);
  const filteredValues = allValues
    .filter((value): value is string => typeof value === 'string')
    .map(normalizeStringValue)
    .filter(value => value.split(' ').length > 1)
    .map(value => {
      const brandWithoutHills = key === 'filterBrand' ? removeFirstIfHills(value) : value;
      const capitalizedValue = capitalizeWords(brandWithoutHills);
      return capitalizedValue;
    });
  return Array.from(new Set(filteredValues)).sort((a, b) => a.localeCompare(b));
};

// Extract unique array values from products
export const extractUniqueArrayValues = (products: HillsProductType[], key: keyof HillsProductType) => {
  const filteredValues = products.flatMap((product) => {
    const value = product[key];
    return Array.isArray(value) ? value : [];
  })
    .filter((value): value is string => typeof value === 'string');
  return Array.from(new Set(filteredValues)).sort();
};

// Extract unique tags map values from products
export const extractUniqueTagsMapValues = (products: HillsProductType[], key: keyof HillsProductType['tagsMap']) => {
  const filteredValues = products
    .flatMap((product) => product.tagsMap?.[key] || [])
    .filter((value): value is string => typeof value === 'string')
    .map(normalizeStringValue);
  return Array.from(new Set(filteredValues)).sort();
};
  
export const sortLifeStages = (lifestages: string[]) => {
  const lifeStageOrder = ["Puppy", "Kitten", "Adult", "Mature", "Senior"];
  return lifestages.sort((a, b) => {
    return lifeStageOrder.indexOf(a) - lifeStageOrder.indexOf(b);
  });
};