import { PetType } from "../../../types";

interface SwiperSlideProps {
  petObj: PetType;
  isSelected: boolean;
  isMobile: boolean;
  onSlideClick: () => void;
  defaultImage: (
    petName: string,
    isSelected: boolean,
    isMobile: boolean
  ) => JSX.Element;
  sixPetsCriteria: boolean;
}

export const SwiperSlide = (petData: SwiperSlideProps) => {
  const {
    petObj,
    defaultImage,
    onSlideClick,
    isSelected,
    isMobile,
    sixPetsCriteria,
  } = petData;

  const heightWidth = isMobile ? "h-[48px] w-[48px]" : "h-[56px] w-[56px]";
  const selectedHeightWidth = isMobile
    ? "h-[56px] w-[56px] border-blue-500 border-solid"
    : "h-[64px] w-[64px]  border-blue-500 border-solid";
  return (
    <div
      data-testid={petObj.petId}
      className={`
      ${isSelected ? selectedHeightWidth : heightWidth} 
      ${
        isMobile && `mx-2`
      } flex cursor-grab flex-col items-center justify-center 
      ${!isMobile && !sixPetsCriteria && `h-24`}
      `}
      onClick={onSlideClick}
    >
      <div
        className={`flex items-center justify-center`}
        data-testid={"pet-name"}
      >
        {petObj.petImage ? (
          <button className="rounded-full focus:border-blue-500">
            <img
              src={petObj.petImage}
              alt={petObj.petName}
              className={`max-w-none 
            rounded-full !border-2 bg-[#E6E6E6] object-cover transition-transform duration-300
            ${isSelected ? selectedHeightWidth : heightWidth}  ${
                !isSelected
                  ? `focus:scale-110 focus:border-blue-500 focus:outline-1 focus:ring-blue-500`
                  : ""
              }`}
              aria-label={`Pet ${petObj.petName}`}
            />
          </button>
        ) : (
          <div
            className={`transition-transform duration-300
         ${isSelected ? selectedHeightWidth : heightWidth}`}
          >
            {defaultImage(petObj.petName, isSelected, isMobile)}
          </div>
        )}
      </div>
    </div>
  );
};
