import React from "react";

export function Cross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      aria-hidden
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3.33333C10.7834 3.33333 3.33337 10.7833 3.33337 20C3.33337 29.2167 10.7834 36.6667 20 36.6667C29.2167 36.6667 36.6667 29.2167 36.6667 20C36.6667 10.7833 29.2167 3.33333 20 3.33333ZM28.3334 25.9833L25.9834 28.3333L20 22.35L14.0167 28.3333L11.6667 25.9833L17.65 20L11.6667 14.0167L14.0167 11.6667L20 17.65L25.9834 11.6667L28.3334 14.0167L22.35 20L28.3334 25.9833Z"
      />
    </svg>
  );
}
