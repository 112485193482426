import { useEffect, useRef, useState } from "react";
import { useFetchNotifications } from "../../api-calls/useFetchNotifications";
import AccountSettings from "../../components/AccountSettings";
import { NOTIFICATION } from "../../constants";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import { MyPetsWrapper } from "./components/MyPetsWrapper";
import RebatePopupWrapper from "./components/RebatePopupWrapper";
import WelcomeUserWrapper from "./components/WelcomeUserWrapper";
import { QOTWWrapper } from "./components/QOTWWrapper";

const AccountHome = () => {
  const { fetchNotificationData, data } = useFetchNotifications(
    NOTIFICATION.REBATE_NOTIFICTION_TYPE
  );
  const { rebateNotification, QotwEnabled } = useFeatureFlagsConfig();

  const initialized = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [hideNotification, setNotificationStatus] = useState(false);

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (!initialized.current) {
      initialized.current = true;
      fetchNotificationData();
    }
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  return (
    <div className="px-5 lg:px-0">
      <div className="hidden lg:block">
        <WelcomeUserWrapper />
      </div>
      {data?.data?.data?.showNotification &&
        rebateNotification &&
        !hideNotification && (
          <div className="mb-8">
            <RebatePopupWrapper
              fetchNotificationData={fetchNotificationData}
              updateNotificationStatus={setNotificationStatus}
            />
          </div>
        )}
      <div className="mb-6">
        <MyPetsWrapper />
      </div>
      <div className="mb-6">
        <AccountSettings />
      </div>
      {QotwEnabled && 
        <div className="mb-6">
          <QOTWWrapper />
        </div>
      }
    </div>
  );
};

AccountHome.defaultProps = {};

export default AccountHome;
