import React, { useState, useEffect } from "react";
import { Datepicker } from "./DatePicker";
import { useTranslation } from "react-i18next";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import { validateDate } from "./validateDate";
import { classnames } from "utils";

const DateInput = ({
  invalid,
  showCalendar,
  monthOnly,
  onChange,
  value,
  errorHint,
  min,
  max,
}: {
  invalid?: boolean;
  showCalendar?: boolean;
  monthOnly?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  errorHint?: string | null;
  min?: Date;
  max?: Date;
}) => {
  const { t } = useTranslation();
  const { dateInputSequence } = useFeatureFlagsConfig();

  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [pristine, setPristine] = useState<boolean>(true);

  useEffect(() => {
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const [yearStr, monthStr, dayStr] = dateParts;
        setYear(yearStr);
        setMonth(monthStr);
        if (!monthOnly) {
          setDay(dayStr);
        }
      }
    }
  }, [value, monthOnly]);

  useEffect(() => {
    updateFormValue(day, month, year);
  }, [day, month, year]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = event.target;
    setPristine(false);
    if (name === "day") {
      setDay(value.slice(0, 2));
    } else if (name === "month") {
      setMonth(value.slice(0, 2));
    } else if (name === "year") {
      setYear(value.slice(0, 4));
    }
  };

  const validation = validateDate({
    day: day || undefined,
    month: month || undefined,
    year: year || undefined,
    min,
    max,
  });

  const updateFormValue = (day: string, month: string, year: string) => {
    if (validation.isValid) {
      let formattedDate = monthOnly
        ? `${year}-${month}-01`
        : `${year}-${month}-${day}`;
      if (!day && !month && !year) {
        formattedDate = "";
      }
      onChange && onChange(formattedDate);
    } else {
      onChange && onChange("");
    }
  };

  const rearrangeInputs = (sequence: string[]) => {
    const buildInputStyles = (hasErrors?: boolean) => {
      const isInvalid =
        !pristine &&
        (hasErrors || invalid || validation.errors?.hasDateErrors());
      return classnames(
        "mr-2 rounded-md border px-2 py-1 text-center text-sm body2",
        "[&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
        isInvalid ? "border-brand-error" : "border-brand-color-library-gray-300"
      );
    };

    const inputs: JSX.Element[] = [];
    sequence.forEach((item) => {
      if (item === "dd" && !monthOnly) {
        inputs.push(
          <div className="flex flex-col" key="day">
            <label className="body3 mr-2 mb-2" htmlFor="day">
              {t("addEditPet.day")}
            </label>
            <input
              id="day"
              name="day"
              type="number"
              placeholder={t("common.dd") || ""}
              value={day}
              className={classnames(
                buildInputStyles(validation.errors?.hasDayErrors()),
                "w-12"
              )}
              onChange={handleInputChange}
              onBlur={(e) => {
                const { value } = e.target;
                if (value?.length == 1) {
                  setDay("0" + value);
                }
                setPristine(false);
              }}
            />
          </div>
        );
      } else if (item === "mm") {
        inputs.push(
          <div className="flex flex-col" key="month">
            <label className="body3 mr-2 mb-2" htmlFor="month">
              {t("addEditPet.month")}
            </label>
            <input
              id="month"
              name="month"
              type="number"
              placeholder={t("common.mm") || ""}
              value={month}
              className={classnames(
                buildInputStyles(validation.errors?.hasMonthErrors()),
                "w-12"
              )}
              onChange={handleInputChange}
              onBlur={(e) => {
                const { value } = e.target;
                if (value?.length == 1) {
                  setMonth("0" + value);
                }
                setPristine(false);
              }}
              min={1}
              max={12}
            />
          </div>
        );
      } else if (item === "yyyy") {
        inputs.push(
          <div className="flex flex-col" key="year">
            <label className="body3 mr-2 mb-2" htmlFor="year">
              {t("addEditPet.year")}
            </label>
            <input
              id="year"
              name="year"
              type="number"
              placeholder={t("common.yyyy") || ""}
              value={year}
              className={classnames(
                buildInputStyles(validation.errors?.hasYearErrors()),
                "w-24"
              )}
              onChange={handleInputChange}
              onBlur={() => setPristine(false)}
            />
          </div>
        );
      }
    });
    return inputs;
  };

  return (
    <div>
      <div className="mb-2 flex items-center">
        {rearrangeInputs(dateInputSequence)}
        {showCalendar && (
          <Datepicker
            name="petBirthdate"
            min={min}
            max={max}
            monthOnly={monthOnly}
            setDay={setDay}
            setMonth={setMonth}
            setYear={setYear}
            initialValue={value}
          />
        )}
      </div>
      {!pristine && validation.errors?.hasErrors() && (
        <div
          className="text-sm text-brand-error"
          data-testid="valid-date-error"
        >
          {errorHint}
        </div>
      )}
    </div>
  );
};

export default DateInput;