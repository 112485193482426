export const ShareIcon = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Share" clipPath="url(#clip0_18410_16843)">
<path id="Vector" d="M12 3.75L10.935 4.815L9.7425 3.6225V12H8.2575V3.6225L7.065 4.815L6 3.75L9 0.75L12 3.75ZM15 7.5V15.75C15 16.575 14.325 17.25 13.5 17.25H4.5C3.6675 17.25 3 16.575 3 15.75V7.5C3 6.6675 3.6675 6 4.5 6H6.75V7.5H4.5V15.75H13.5V7.5H11.25V6H13.5C14.325 6 15 6.6675 15 7.5Z" fill="#0054A4"/>
</g>
<defs>
<clipPath id="clip0_18410_16843">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
)