import { MY_PETS } from "../../constants/myPets";
import { useMyPets } from "../../hooks/useMyPet";
import { CatBlue } from "../../icons/CatBlue";
import { DogBlue } from "../../icons/DogBlue";
import { RemembredPetsIcon } from "../../icons/RememberedPetIcon";
import { useTranslation } from "react-i18next";
import GTM_ACTIONS from "../../config/gtmActions"; 
import { datalayerPush } from "../../lib/gtm";

export const RememberedPets = () => {
  const { isViewRememberedPets, setIsViewRememberedPets, showRememberedPetsLabel } = useMyPets();
  const { t } = useTranslation();

  const toggleRemembredPets = (isRemembredPetsView: boolean) => {
    if (!isViewRememberedPets && isRemembredPetsView) {
      datalayerPush(GTM_ACTIONS.MY_PETS_PROFILE_CLICK_REMEMBERED_PETS);
    }
    setIsViewRememberedPets && setIsViewRememberedPets(isRemembredPetsView);
  };

  const catDog = (
    <div className="flex">
      <span>{DogBlue}</span>
      <span>{CatBlue}</span>
    </div>
  );

  if(!showRememberedPetsLabel){
    return null;
  }
  return (
    <div className="flex justify-center">
      <div>
        <hr className="w-72 text-center" />
        <button
          onClick={() => {
            toggleRemembredPets(!isViewRememberedPets);
          }}
          className="m-2 mt-6 h-[30px]"
        >
          <div className="flex items-center text-brand-primary">
            <div className="p-1">
              {isViewRememberedPets ? catDog : RemembredPetsIcon}
            </div>
            <div className="body1">
              {t(
                isViewRememberedPets
                  ? MY_PETS.REMEMBRED_PETS.VIEW_MY_PETS
                  : MY_PETS.REMEMBRED_PETS.LABEL
              )}
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
