import axios from "axios";
import { useApi } from "utils";
import { API_URLS } from "../constants/apiUrls";
import { AddPetRequest } from "../types/pets";

export const useSetUpdatePet = (data: AddPetRequest) => {
  const filterData = data.petData.map(pet => {
    // eslint-disable-next-line
    const { petImage, ...rest } = pet;
    return rest;
  })
  const formattedData = {
    petData: filterData
  };

  const { exec: setUpdatePetData } = useApi(async () => {
    const formData = new FormData();

    formData.append("data", JSON.stringify(formattedData));
    if (data?.petData?.[0]?.petImage?.file && data?.petData?.[0]?.petImage?.rendition) {
      const imageFile = data.petData[0].petImage.file;
      const rendition = data.petData[0].petImage.rendition;

      formData.append("image", imageFile);
      formData.append("rendition", JSON.stringify(rendition));
    }

    return axios
      .put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          return apiResponse?.data;
        }
      });
  });

  return { setUpdatePetData };
};