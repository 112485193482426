import { isDate } from "date-fns";
import { useTranslation } from "react-i18next";
import { formatMilestoneDate } from "utils";
import * as Modal from "../../../../components/Modal";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { LIFESTAGE } from "../../../../constants/lifestage";
import { PencilIcon } from "../../../../icons/PencilIcon";
import { datalayerPush } from "../../../../lib/gtm";
import { EditMilestoneForm } from "./EditMilestoneForm";

export function CompletedMilestoneItem({
  id,
  petId,
  title,
  date,
  description,
}: Readonly<{
  id: string;
  petId: string;
  title: string;
  date: Date | null;
  description: string;
}>) {
  const { t } = useTranslation();
  const dateString = isDate(date) ? formatMilestoneDate(date as Date) : "";

  return (
    <div
      onClick={() => datalayerPush(GTM_ACTIONS.CLICK_A_COMPLETED_MILESTONE)}
      className="flex w-full flex-col gap-2 rounded bg-brand-color-library-blue-100
          bg-right-bottom bg-no-repeat p-4 shadow-dp2 md:p-6"
    >
      <div className="grid_hphv2 grid-cols-[44px_auto_24px] gap-4 md:grid-cols-[52px_auto_24px]">
        <img
          className="h-12 w-11 md:h-14 md:w-[52px]"
          src={process.env.REACT_APP_URL + "/images/medal.png"}
          alt="medal"
          aria-hidden
        />
        <div className="overflow-hidden">
          <h3 className="label1 mb-1 truncate text-brand-color-library-gray-900">
            {title}
          </h3>
          <p className="body2 truncate text-brand-color-library-gray-500">
            {dateString}
          </p>
        </div>
        <div>
          <Modal.Root>
            <Modal.Trigger asChild>
              <button aria-label="Edit milestone">
                <PencilIcon className="h-6 w-6 fill-brand-color-library-blue-500" />
              </button>
            </Modal.Trigger>
            <Modal.SideModal title={t(LIFESTAGE.EDIT_MILESTONE)}>
              <div className="w-full">
                <div className="m-auto max-w-[554px] px-5 pt-6 md:pt-8">
                  <Modal.Description asChild>
                    <>
                      <h3 className="headline4 md:headline3 mb-6">{title}</h3>
                      <p className="body3 md:body2 mb-6">{description}</p>
                    </>
                  </Modal.Description>
                  <EditMilestoneForm
                    petId={petId}
                    milestoneId={id}
                    date={date}
                  />
                </div>
              </div>
            </Modal.SideModal>
          </Modal.Root>
        </div>
      </div>
      <p className="body2 text-brand-color-library-gray-800">{description}</p>
    </div>
  );
}
