import { MY_PETS } from "../../../../constants/myPets";

export function getLifestageCode({
  petType,
  lifestageId,
}: {
  petType: string;
  lifestageId: number;
}) {
  const lifestages: Record<number, string> =
    petType === "dog"
      ? {
          1: MY_PETS.MILESTONES.PUPPY,
          2: MY_PETS.MILESTONES.ADULT_DOG,
          3: MY_PETS.MILESTONES.SENIOR_DOG,
        }
      : {
          1: MY_PETS.MILESTONES.KITTEN,
          2: MY_PETS.MILESTONES.ADULT_CAT,
          3: MY_PETS.MILESTONES.SENIOR_CAT,
        };

  return lifestages[lifestageId];
}
