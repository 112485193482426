import { useTranslation } from "react-i18next";
import { Button } from "ui-2";
import { QOTW } from "../../constants/qotw";
import * as Modal from "@components/Modal";
import { useQOTW } from "@pages/AccountHome/components/QOTWWrapper";

export const QOTWCard = () => {
  const { t } = useTranslation();
  const state = useQOTW();

  if (state.isSuccess) {
    return (
      <div className="rounded-lg bg-[#EEF5FF] p-4 md:p-6">
        <div className="">
          <img
            className="mr-4 inline-block h-10 w-10"
            src={process.env.REACT_APP_URL + "/images/qotw.svg"}
            alt="Question of the Week"
          />
          <span className="label2 md:label1" data-testid="header">
            {t(QOTW.TITLE)}
          </span>
        </div>
        {state?.data?.isAnswered ? (
          <div className="body3 md:body2 py-6" data-testid="body">
            {t(QOTW.ALREADY_ANSWERED_TEXT)}
          </div>
        ) : (
          <>
            <div className="body3 md:body2 py-6" data-testid="body">
              {t(state?.data?.text)}
            </div>
            <div className="text-end">
              <Modal.Trigger asChild>
                <Button
                  variant="primary"
                  size="lg"
                  type="button"
                  classes="md:px-8 label2 md:label1 w-full md:w-fit md:min-w-[208px]"
                >
                  {t(QOTW.CTA)}
                </Button>
              </Modal.Trigger>
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
};
