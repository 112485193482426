export type FilterConfig = {
  key: "filterBrand" | "productForm" | "lifestage" | "healthCategory" | "productFamily";
  title: string;
  isFirstAccordion: boolean;
  useTopLevelExtraction?: boolean; // Optional property to indicate which extraction function to use
};

export const filterConfigs: FilterConfig[] = [
  { key: 'filterBrand', title: 'Brand', isFirstAccordion: true, useTopLevelExtraction: true },
  { key: "productForm", title: "Product Type", isFirstAccordion: false },
  { key: "lifestage", title: "Lifestage", isFirstAccordion: false },
  { key: "healthCategory", title: "Health Conditions", isFirstAccordion: false, useTopLevelExtraction: true },
  { key: "productFamily", title: "Additional Needs", isFirstAccordion: false },
];
