import React, { createContext, useContext, useState } from "react";
import { ToastConfigProps, ToastContextProps } from "./ToastTypes";

import Toast from "./Toast";

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export function ToastProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const [toastState, setToastState] = useState(false);
  const [toastConfig, setToastConfig] = useState<ToastConfigProps>({});

  const showToast = React.useCallback(function showToast(
    config: ToastConfigProps
  ) {
    setToastConfig(config);
    setToastState(true);
  },
  []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast
        toastConfig={toastConfig}
        toastState={toastState}
        setToastState={setToastState}
      />
    </ToastContext.Provider>
  );
}

export const useToastConfig = () => {
  return useContext(ToastContext);
};
