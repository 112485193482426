import React from "react";

export function Link(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      aria-hidden
      {...props}
    >
      <path d="M16.5 17.4167H5.5C4.99583 17.4167 4.58333 17.0042 4.58333 16.5V5.5C4.58333 4.99583 4.99583 4.58333 5.5 4.58333H10.0833C10.5875 4.58333 11 4.17083 11 3.66667C11 3.1625 10.5875 2.75 10.0833 2.75H4.58333C3.56583 2.75 2.75 3.575 2.75 4.58333V17.4167C2.75 18.425 3.575 19.25 4.58333 19.25H17.4167C18.425 19.25 19.25 18.425 19.25 17.4167V11.9167C19.25 11.4125 18.8375 11 18.3333 11C17.8292 11 17.4167 11.4125 17.4167 11.9167V16.5C17.4167 17.0042 17.0042 17.4167 16.5 17.4167ZM12.8333 3.66667C12.8333 4.17083 13.2458 4.58333 13.75 4.58333H16.1242L7.755 12.9525C7.3975 13.31 7.3975 13.8875 7.755 14.245C8.1125 14.6025 8.69 14.6025 9.0475 14.245L17.4167 5.87583V8.25C17.4167 8.75417 17.8292 9.16667 18.3333 9.16667C18.8375 9.16667 19.25 8.75417 19.25 8.25V3.66667C19.25 3.1625 18.8375 2.75 18.3333 2.75H13.75C13.2458 2.75 12.8333 3.1625 12.8333 3.66667Z" />
    </svg>
  );
}
