import {
  fetchAllMilestones,
  type MilestonesData,
  type Milestones,
} from "../../../../api-calls/fetchMilestones";
import React from "react";
import { useTranslation } from "react-i18next";
import { StateWithShortcuts, usePromise } from "utils";
import { getLifestageCode } from "../LifestageSwiper/utils";

type MilestonesValue = StateWithShortcuts<Milestones> & {
  lifestageIdx: number;
  setLifestageIdx: React.Dispatch<React.SetStateAction<number>>;
  currentMilestones?: MilestonesData;
  lifestageLabels: string[];
  activeLifestageLabel: string;
  refetchMilestones: () => void;
};

const MilestonesContext = React.createContext<MilestonesValue | undefined>(
  undefined
);
MilestonesContext.displayName = "MilestonesContext";

export function MilestonesProvider({
  initialLifestageId,
  petId,
  petType,
  children,
}: Readonly<{
  initialLifestageId: number;
  petId: string;
  petType: string;
  children: React.ReactNode;
}>) {
  const [lifestageIdx, setLifestageIdx] = React.useState(1);
  const [status, { exec }] = usePromise(fetchAllMilestones);

  React.useEffect(() => {
    exec({ petId, initialLifestageId });
  }, [petId, exec]);

  React.useEffect(() => {
    const lifestageIdx = status.data?.find(
      (m) => m.lifestageId === initialLifestageId
    )?.index;

    lifestageIdx && setLifestageIdx(lifestageIdx);
  }, [status.data, initialLifestageId]);

  const currentMilestones = status.data?.find((m) => m.index === lifestageIdx);

  const { t } = useTranslation();
  const lifestageLabels = React.useMemo(
    () => [
      t(getLifestageCode({ petType, lifestageId: 1 })),
      t(getLifestageCode({ petType, lifestageId: 2 })),
      t(getLifestageCode({ petType, lifestageId: 3 })),
    ],
    [petType]
  );
  const activeLifestageLabel = lifestageLabels[lifestageIdx - 1];

  const refetchMilestones = React.useCallback(
    function refetchMilestones() {
      exec({ petId, initialLifestageId });
    },
    [exec, petId, initialLifestageId]
  );

  const value = {
    ...status,
    lifestageIdx,
    setLifestageIdx,
    currentMilestones,
    lifestageLabels,
    activeLifestageLabel,
    refetchMilestones,
  };

  return (
    <MilestonesContext.Provider value={value}>
      {children}
    </MilestonesContext.Provider>
  );
}

export function useMilestones() {
  const context = React.useContext(MilestonesContext);
  if (context === undefined) {
    throw new Error("useMilestones must be used within a MilestonesProvider");
  }
  return context;
}
