import KebabMenu from "./components/KebabMenu";
import { ShareProfilePrint } from "./components/ShareProfilePrint";
import { PetType } from "../../../../../types";
import { useEffect, useRef, useState } from "react";
import { useFoodSelectDataContext } from "@hooks/FoodSelectDataContext";
import { renderPDF, sharePDF } from "@lib/common/pdf";
import { useFeatureFlagsConfig } from "@hooks/useFeatureFlags";
import { useToastConfig } from "@components/toast";
import { MY_PETS } from "@constants/myPets";
import { t } from "i18next";
import { FOOD_TYPES } from "@constants/foodTypes";

const PetsNameHeadingWrapper = ({
  petData,
  setLoader,
}: {
  petData: PetType;
  setLoader: (show: boolean) => void;
}) => {
  const [isSharing, setIsSharing] = useState(false);
  const { loadHillsProducts, hillsProducts, isDataLoading } =
    useFoodSelectDataContext();
  const [foodSelectProduct, setFoodSelectProduct] = useState<
    | {
        id: string;
        image: string;
        name: string;
        feedingGuide: string;
      }
    | null
    | undefined
  >(undefined);
  const targetRef = useRef<HTMLDivElement>(null);
  const { sharePetProfileEnabled } = useFeatureFlagsConfig();
  const { showToast } = useToastConfig();
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const isPdfDataReady = !!petData && foodSelectProduct !== undefined;

  useEffect(() => {
    if (!isDataLoading) {
      // Call loadHillsProducts only if the data is not already loading
      loadHillsProducts(petData.petTypeId, true);
    }
  }, [petData.petTypeId, isDataLoading, loadHillsProducts]);

  useEffect(() => {
    if (isDataLoading || hillsProducts.length === 0) {
      return;
    }

    if (
      petData.petFoodTypeId?.toString() === FOOD_TYPES.HILLS_PRODUCT &&
      petData.petFoodTypeInfo
    ) {
      // Find the product details based on petFoodTypeInfo
      const productDetails = hillsProducts.find(
        (product) => product.id === petData.petFoodTypeInfo
      );
      if (productDetails) {
        setFoodSelectProduct({
          id: productDetails.id,
          image: productDetails.primaryProductImageUrl,
          name: productDetails.name,
          feedingGuide: productDetails.feedingGuide || "",
        });
      } else {
        setFoodSelectProduct(null);
      }
    } else {
      setFoodSelectProduct(null);
    }
  }, [
    petData.petId,
    petData.petFoodTypeId,
    petData.petFoodTypeInfo,
    hillsProducts,
    isDataLoading,
  ]);

  useEffect(() => {
    setPdfBlob(null);
  }, [petData]);

  const handlePrepareShare = async () => {
    if (!pdfBlob && isPdfDataReady) {
      setIsSharing(true);
      try {
        const petTitle = t(MY_PETS.SHARE_PET_PROFILE.PET_TITLE, {
          petName: petData.petName,
        });
        const newPdfBlob = await renderPDF(targetRef, `${petTitle}.pdf`);
        setPdfBlob(newPdfBlob);
      } catch (error) {
        console.error("Error preparing PDF:", error);
        showToast({
          description: t(MY_PETS.SHARE_PET_PROFILE.SHARE_PET_PROFILE_ERROR),
          type: "error",
        });
      } finally {
        setIsSharing(false);
      }
    }
  };

  const handleShareProfileClick = async () => {
    if (pdfBlob) {
      try {
        const petTitle = t(MY_PETS.SHARE_PET_PROFILE.PET_TITLE, {
          petName: petData.petName,
        });
        await sharePDF(
          pdfBlob,
          `${petTitle}.pdf`,
          petTitle,
          t(MY_PETS.SHARE_PET_PROFILE.SHARE_PET_PROFILE)
        );
      } catch (error) {
        console.error("Error sharing PDF:", error);
        showToast({
          description: t(MY_PETS.SHARE_PET_PROFILE.SHARE_PET_PROFILE_ERROR),
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="headline4 lg:headline1 ml-5 flex items-center">
        <span>{petData.petName}</span>
        <KebabMenu
          petData={petData}
          setLoader={setLoader}
          handlePrepareShare={handlePrepareShare}
          handleShareProfileClick={handleShareProfileClick}
          isSharing={isSharing}
          sharePetProfileEnabled={sharePetProfileEnabled}
          isPdfDataReady={isPdfDataReady}
        />
      </div>
      <div ref={targetRef} className="hidden w-[834px]">
        <ShareProfilePrint
          nickname={petData.petName}
          breedId={petData.petBreedId ?? 0}
          genderId={petData.petGenderId ?? 0}
          birthdate={
            petData.petBirthday ? new Date(petData.petBirthday) : new Date()
          }
          petTypeId={petData.petTypeId ?? 0}
          sizeId={petData.petSizeId ?? 0}
          productId={foodSelectProduct?.id}
          productName={foodSelectProduct?.name}
          feedingGuide={foodSelectProduct?.feedingGuide}
        />
      </div>
    </>
  );
};

export default PetsNameHeadingWrapper;
