import axios from "axios";
import { API_URLS } from "../constants/apiUrls";
import { Auth } from "../hooks/useAuth";

export const fetchPetConfig = (auth: Auth, signal: AbortSignal) => {
  return axios
    .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PET_CONFIG}`, {
      signal,
      headers: { locale: auth.locale as string },
    })
    .then((apiResponse) => {
      if (apiResponse.data.serviceStatus.code === 200) {
        const petStatusObj = apiResponse.data.data.petStatus.reduce(
          (
            accumulator: object,
            value: { petStatusId: number; petStatus: string }
          ) => {
            return { ...accumulator, [value.petStatus]: value.petStatusId };
          },
          {}
        );

        const petRemovalReasonObj =
          apiResponse.data.data.petRemovalReasons.reduce(
            (accumulator: object, value: { id: number; name: string }) => {
              return { ...accumulator, [value.id]: value.name };
            },
            {}
          );
        const petTypesByName = apiResponse.data.data.petTypes.reduce(
          (
            accumulator: object,
            value: { petTypeId: number; petTypeName: string }
          ) => {
            return {
              ...accumulator,
              [value.petTypeName]: value.petTypeId.toString(),
            };
          },
          {}
        );
        apiResponse.data.data.petSizes.forEach(
          (v: { petSizeId: string }) => (v.petSizeId += "")
        );
        apiResponse.data.data["petTypesByName"] = petTypesByName;
        apiResponse.data.data["petStatusByName"] = petStatusObj;
        apiResponse.data.data["petRemovalReasonById"] = petRemovalReasonObj;
        return apiResponse.data.data;
      }
    });
};