import axios from "axios";
import { useApi } from "utils";
import { API_URLS } from "../constants/apiUrls";
import { Auth, useAuth } from "../hooks/useAuth";

export const useFetchNotifications = (
    notificationID: number,
  ) => {
    const auth: Auth = useAuth();

    const { exec: fetchNotificationData, data } = useApi(() => {
        return axios
            .get(`${process.env.REACT_APP_PROFILE_API_URL}${API_URLS.PARENT_NOTIFICATIONS}`,
                { headers: { locale: window.hph_locale, UID: auth.user?.UID, notificationtypeid: notificationID } })
    });

    return { fetchNotificationData, data }
};