import React, { useState, createContext, useEffect } from "react";
import { usePromise } from "utils";
import { fetchPets } from "../api-calls/fetchPets";
import { PetType } from "../../types";
import { StateWithShortcuts } from "utils";

interface FetchPetsResponse {
  data: {
    data: PetType[];
    serviceStatus: {
      message: string;
    };
  };
}
interface MyPetsValue {
  isViewRememberedPets: boolean;
  showRememberedPetsLabel: boolean;
  setIsViewRememberedPets: (isViewRememberedPets: boolean) => void;
  setShowRememberedPetsLabel: (showRememberedPets: boolean) => void;
  refetchPetList: () => void;
  status: StateWithShortcuts<FetchPetsResponse>;
}

const MyPetsPetsContext = createContext<MyPetsValue | undefined>(undefined);
MyPetsPetsContext.displayName = "MyPetsPetsContext";

export function MypetsPetsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isViewRememberedPets, setIsViewRememberedPets] =
    useState<boolean>(false);
  const [showRememberedPetsLabel, setShowRememberedPetsLabel] =
    useState<boolean>(false);

  const [status, { exec: refetchPetList }] = usePromise(fetchPets);

  useEffect(() => {
    refetchPetList();
  }, []);

  return (
    <MyPetsPetsContext.Provider
      value={{
        status,
        isViewRememberedPets,
        showRememberedPetsLabel,
        setShowRememberedPetsLabel,
        setIsViewRememberedPets,
        refetchPetList,
      }}
    >
      {children}
    </MyPetsPetsContext.Provider>
  );
}

export function useMyPets() {
  const context = React.useContext(MyPetsPetsContext);
  if (context === undefined) {
    throw new Error(
      "useMyPets must be used within a MyPetsPetsContextProvider"
    );
  }
  return context;
}
