import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import "./i18n";

function renderApp() {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <HashRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </HashRouter>
    </React.StrictMode>
  );
}

if (process.env.NODE_ENV === "development") {
  require("custom-gigya-events/static/init.js");
  import("./mocks/server/dev-server").then(async ({ worker }) => {
    await worker.start({ onUnhandledRequest: "bypass" });
    renderApp();
  });
} else {
  renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
