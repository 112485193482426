/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANG } from "./constants/languages";
import requireContext from 'require-context.macro';

/**
 * function to get locale code from window object set by AEM
 * or else fall back to default EN_US
 * @returns language
 */
export function getCurrentLocale() {
  return window.hph_locale ? window.hph_locale : LANG.EN_US;
}

/** Read all the supported locales (comma separted string) from env for a site
 * and add EN_US for fallback
 * Intl.getCanonicalLocales((process.env.REACT_APP_LANGUAGES || LANG.EN_US).split(',')); 
 */
const supportedLocales = (process.env.REACT_APP_LANGUAGES || LANG.EN_US).split(',');
if (!supportedLocales.includes(LANG.EN_US)) {
  supportedLocales.push(LANG.EN_US);
}

/** Read all JSON files from ./locales folder
 * and add only files
 * matching with supported locales to the resources option for i18n
 */
const files: __WebpackModuleApi.RequireContext = requireContext('./locales', true, /\.json$/);
const resources: { [key: string]: any } = files.keys().filter((filename: string) => {
  return supportedLocales.some((term: string) => {
    return filename.includes('/' + term.toLowerCase() + '/');
  });
}).reduce((acc: { [key: string]: any }, curr: string) => {
  let locale: string = curr.split('/')[1];
  locale = locale.includes('-') ? locale.split('-')[0].concat('-').concat(locale.split('-')[1].toUpperCase()) : locale;
  acc[locale] = {
    translation: files(curr)
  };
  return acc;
}, {});

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use({
    type: "languageDetector",
    async: false,
    // Use the locale that is set in window object as current locale
    detect: getCurrentLocale,
    cacheUserLanguage: function () {
      return;
    },
  })
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: LANG.EN_US,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then(() => {
    const params = new URLSearchParams(window.location.hash.split('?')[1] || '');
    const lng = params.get("lng");
    if (lng === "cimode") {
      i18n.changeLanguage("cimode");
    }
  });

export default i18n;
