import { fetchQOTW, FetchQOTWResponse } from "@api-calls/fetchQOTW";
import * as Modal from "@components/Modal";
import { QOTWCard } from "@components/QOTWCard";
import { QOTWForm } from "@components/QOTWForm";
import { QOTW } from "@constants/qotw";
import React from "react";
import { useTranslation } from "react-i18next";
import { StateWithShortcuts, usePromise } from "utils";

export function QOTWWrapper() {
  return (
    <QOTWProvider>
      <QOTWComponent />
    </QOTWProvider>
  );
}

function QOTWComponent() {
  const { t } = useTranslation();
  const state = useQOTW();

  if (state.isSuccess) {
    return (
      <Modal.Root>
        <QOTWCard />
        <Modal.SideModal title={t(QOTW.TITLE)}>
          <QOTWForm questionData={state.data} />
        </Modal.SideModal>
      </Modal.Root>
    );
  }

  return null;
}

type QOTWValue = StateWithShortcuts<FetchQOTWResponse> & {
  refetch: () => Promise<FetchQOTWResponse | undefined>;
};

const QOTWContext = React.createContext<QOTWValue | undefined>(undefined);
QOTWContext.displayName = "QOTWContext";

function QOTWProvider(props: React.PropsWithChildren<unknown>) {
  const [state, { exec: execFetchQOTW }] = usePromise(fetchQOTW);
  React.useEffect(() => {
    execFetchQOTW();
  }, [execFetchQOTW]);

  const value = { ...state, refetch: execFetchQOTW };
  return <QOTWContext.Provider {...props} value={value} />;
}

export function useQOTW() {
  const context = React.useContext(QOTWContext);
  if (context === undefined) {
    throw new Error("useQOTW must be used within a QOTWProvider");
  }

  return context;
}
