import axios from "axios";

export interface PostAnswerResponse {
  correctId: string;
}

export async function postQOTWAnswer({ optionId }: { optionId: string }) {
  return await axios
    .post<PostAnswerResponse>(
      `${process.env.REACT_APP_QOTW_API_URL}/v1/answer`,
      { optionId }
    )
    .then((res) => res.data);
}
