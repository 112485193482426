import * as ToastPrimitive from "@radix-ui/react-toast";

import {
  IconCloseLg,
  IconToastCross,
  IconToastSuccess,
  IconWarning,
} from "ui-2";
import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { ToastStatesProps } from "./ToastTypes";
import { classnames } from "utils";

const toastTypes = {
  error: "bg-red-100 border-2 border-red-300 text-red-800",
  success: "bg-green-100 border-2 border-green-300 text-green-800",
  warning: "bg-orange-100 border-2 border-orange-400 text-orange-700",
};
const iconMap = {
  success: <IconToastSuccess />,
  error: <IconToastCross />,
  warning: <IconWarning />,
};
const position = {
  topRight: "lg:left-auto lg:right-10",
  topLeft: "lg:right-auto lg:left-8",
  bottomLeft: "lg:right-auto lg:left-8",
  bottomRight: "lg:left-auto lg:right-8",
  top: "top-16",
  bottom: "bottom-20",
};
const ToastClose = ToastPrimitive.Close;

const DEFAULT_ANIMATION_DURATION = 3000;

const Toast = ({
  toastConfig,
  toastState,
  setToastState,
}: ToastStatesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (toastState) {
      setIsOpen(true);
      setShouldAnimate(true);

      timer = setTimeout(() => {
        setIsOpen(false);
        setToastState(false);
      }, toastConfig?.duration ?? DEFAULT_ANIMATION_DURATION);
    } else {
      setIsOpen(false);
    }

    return () => clearTimeout(timer);
  }, [toastState, toastConfig]);

  const handleClose = React.useCallback(
    function handleClose() {
      setIsOpen(false);
      setToastState(false);
      setShouldAnimate(false);
    },
    [setToastState]
  );

  const content = React.useMemo(() => {
    return (
      <ToastPrimitive.Provider
        swipeDirection="right"
        duration={toastConfig?.duration ?? DEFAULT_ANIMATION_DURATION}
      >
        <ToastPrimitive.Root
          open={isOpen}
          onOpenChange={setIsOpen}
          className={classnames(
            "body1 flex items-center justify-between gap-4 rounded py-3 px-4 text-center font-normal shadow-dp8 lg:w-auto",
            toastTypes[toastConfig.type ?? "success"],
            shouldAnimate && isOpen && "slide-in",
            shouldAnimate && !isOpen && "slide-out"
          )}
        >
          {iconMap[toastConfig?.type ?? "success"]}
          <ToastPrimitive.Description>
            {toastConfig?.description}
          </ToastPrimitive.Description>
          <ToastClose aria-label="Close" onClick={handleClose}>
            <IconCloseLg />
          </ToastClose>
        </ToastPrimitive.Root>
        <ToastPrimitive.Viewport
          className={classnames(
            "lg:translate-none fixed left-1/2 z-[900] h-auto w-[280px] -translate-x-1/2 lg:w-auto lg:-translate-x-0",
            position[toastConfig?.position?.desktop ?? "topRight"],
            position[toastConfig?.position?.mobile ?? "top"]
          )}
        />
      </ToastPrimitive.Provider>
    );
  }, [toastConfig, isOpen, shouldAnimate, handleClose]);

  // If a modal root is present, render the toast inside it to prevent the modal's
  // focus trap from disabling interaction with the toast
  const modalRoot = document.getElementById("modal-toast-root");
  if (modalRoot) {
    return ReactDOM.createPortal(content, modalRoot);
  }

  return content;
};

export default Toast;
