import { useState, useRef } from 'react';
import { CloseIcon } from '../../../../icons/closeIcon';
import { searchIcon } from "../../../../icons/searchIcon";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  onSearchInputChange: (query: string) => void;
  onSearchExecute: () => void; 
  searchSuggestions: string[]; 
  searchQuery: string; 
  setSearchQuery: (query: string) => void; 
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearchInputChange,
  onSearchExecute,
  searchSuggestions,
  searchQuery, 
  setSearchQuery, 
}) => {
  const { t } = useTranslation();
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearchInputChange(query);
    setIsSuggestionsVisible(query.length > 0 && searchSuggestions.length > 0);
    setHighlightedIndex(null);
  };

  const handleInputFocus = () => {
    if (searchQuery.length > 0) {
      setIsSuggestionsVisible(true);
    }
  };

  const handleClearInput = () => {
    setSearchQuery('');
    onSearchInputChange('');
    setIsSuggestionsVisible(false);
  };

  useOnClickOutside(searchBarRef, () => {
    setIsSuggestionsVisible(false);
  });

  const renderSuggestion = (suggestion: string) => {
    const matchIndex = suggestion.toLowerCase().indexOf(searchQuery.toLowerCase());
    if (matchIndex === -1) {
      return <span className="font-bold">{suggestion}</span>;
    }
    const beforeMatch = suggestion.slice(0, matchIndex);
    const matchText = suggestion.slice(matchIndex, matchIndex + searchQuery.length);
    const afterMatch = suggestion.slice(matchIndex + searchQuery.length);
    return (
      <>
        <span className="font-bold">{beforeMatch}</span>
        <span className="font-normal">{matchText}</span>
        <span className="font-bold">{afterMatch}</span>
      </>
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === searchSuggestions.length - 1 ? 0 : prevIndex + 1
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === 0 ? searchSuggestions.length - 1 : prevIndex - 1
      );
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (highlightedIndex !== null) {
        const selectedSuggestion = searchSuggestions[highlightedIndex];
        setSearchQuery(selectedSuggestion);
        onSearchExecute();
        setIsSuggestionsVisible(false);
      } else {
        onSearchExecute();
        setIsSuggestionsVisible(false);
      }
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchQuery(suggestion);
    onSearchExecute();
    setIsSuggestionsVisible(false);
    setHighlightedIndex(null);
  };

  return (
    <div className="mb-4 relative mx-2 lg:ml-4 lg:mr-0" ref={searchBarRef}>
      <div className="flex max-w items-center gap-4 rounded-full border border-solid border-gray-300 hover:border-gray-900 lg:rounded bg-gray-100">
        <input
          type="text"
          placeholder={t("foodSelect.search") ?? ""}
          value={searchQuery}  // Using unified search state
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          className="flex-1 py-2 px-4 bg-transparent border-none focus:outline-none rounded"
          aria-label={t("foodSelect.search") ?? ""}
        />
        {searchQuery && (
          <button onClick={handleClearInput} className="mr-2" aria-label={t("foodSelect.clearAll") ?? "Clear Search"}>
            <CloseIcon className="h-5 w-5" />
          </button>
        )}
        <button type="button" onClick={() => onSearchExecute()} className="search-button pr-2" aria-label={t("foodSelect.search") ?? ""}>
          {searchIcon}
        </button>
      </div>
      {isSuggestionsVisible && searchSuggestions.length > 0 && (
        <div className="absolute w-full z-10 max-h-48 overflow-y-auto bg-white border border-gray-200 rounded shadow-md mt-1" role="listbox" aria-label={t("foodSelect.suggestions") ?? "Suggestions"}>
          {searchSuggestions.map((suggestion, index) => (
            <div
              key={suggestion}
              className={`p-2 cursor-pointer hover:bg-gray-100 ${index === highlightedIndex ? 'bg-gray-100' : ''}`}
              onClick={() => handleSuggestionClick(suggestion)}
              onMouseEnter={() => setHighlightedIndex(index)}
              onMouseLeave={() => setHighlightedIndex(null)}
              role="option"
              aria-selected={index === highlightedIndex}
            >
              {renderSuggestion(suggestion)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;