import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "ui-2";
import {
  DietInfoCardProps,
  HillsProductType,
  PetType,
} from "../../../../../types";
import DietInfoCard from "../../../../components/DietInfoCard/DietInfoCard";
import { FeedingGuideAccordion } from "../../../../components/DietInfoCard/FeedingGuide/FeedingGuideAccordion";
import { FeedingGuideTitle } from "../../../../components/DietInfoCard/FeedingGuide/FeedingGuideTitle";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { MY_PETS } from "../../../../constants/myPets";
import { useFoodSelectDataContext } from "../../../../hooks/FoodSelectDataContext";
import { datalayerPush } from "../../../../lib/gtm";

const DietInfoCardWrapper = ({
  petData,
  onFoodSelectionSaved,
  onClose,
}: {
  petData: PetType;
  onFoodSelectionSaved: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { hillsProducts, isDataLoading, loadHillsProducts } =
    useFoodSelectDataContext();
  const [petFood, setFood] = useState<HillsProductType | null>(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const refetchData = useCallback(() => {
    // Function to refetch hillsProducts and update petFood
    if (petData.petFoodTypeId === 1) {
      loadHillsProducts(petData.petTypeId, true);
    }
  }, [petData.petFoodTypeId, petData.petTypeId, loadHillsProducts]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  useEffect(() => {
    if (!isDataLoading && hillsProducts.length > 0) {
      filterPetFood();
    }
  }, [isDataLoading, hillsProducts]);

  const filterPetFood = () => {
    const food = hillsProducts.find(
      (food) => food.id === petData.petFoodTypeInfo
    );
    setFood(food || null);
  };

  const toggleAccordion = () => {
    if (!isAccordionOpen) {
      datalayerPush(GTM_ACTIONS.MY_PETS_DIET_CLICK_FEEDING_GUIDE);
    }
    setIsAccordionOpen(!isAccordionOpen);
  };

  const feedingGuideTitle = petFood ? t(MY_PETS.DIET.FEEDING_GUIDE) : null;

  const DietData: DietInfoCardProps = {
    petId: petData.petId,
    petFoodTypeId: petData.petFoodTypeId,
    petFoodTypeInfo: petData.petFoodTypeInfo,
    hillsProductImage: petFood?.primaryProductImageUrl,
    hillsProductDesc: petFood?.name,
    petName: petData.petName,
    petType: Number(petData.petTypeId) === 1 ? "dog" : "cat",
    petTypeId: petData.petTypeId,
  };

  if (isDataLoading) return <Loader />;
  return (
    <DietInfoCard
      DietData={DietData}
      onFoodSelectionSaved={onFoodSelectionSaved}
      onClose={onClose}
    >
      {petData.petFoodTypeId === 1 && (
        <>
          {feedingGuideTitle && (
            <FeedingGuideTitle
              title={feedingGuideTitle}
              isOpen={isAccordionOpen}
              toggleAccordion={toggleAccordion}
            />
          )}
          {isAccordionOpen && petFood?.feedingGuide && (
            <FeedingGuideAccordion
              contentHtml={petFood.feedingGuide}
              isOpen={isAccordionOpen}
            />
          )}
        </>
      )}
    </DietInfoCard>
  );
};

export default DietInfoCardWrapper;
