import axios from "axios";

export interface FetchQOTWResponse {
  id: string;
  text: string;
  isAnswered: boolean;
  options: {
    id: string;
    text: string;
  }[];
}

export async function fetchQOTW() {
  return await axios
    .get<FetchQOTWResponse>(
      `${process.env.REACT_APP_QOTW_API_URL}/v1/question-of-the-week`
    )
    .then((res) => res.data);
}
