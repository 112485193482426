import { format, isValid, parse, isAfter } from "date-fns";
import { type Config } from "final-form";
import { useCallback, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui-2";
import { usePromise } from "utils";
import { updateMilestone } from "../../../../api-calls/updateMilestone";
import DateInput from "../../../../components/DateInput";
import * as Modal from "../../../../components/Modal";
import { useToastConfig } from "../../../../components/toast";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { LIFESTAGE, SAVE } from "../../../../constants/index";
import { datalayerPush } from "../../../../lib/gtm";
import { useMilestones } from "./context";
import { DeleteMilestoneModal } from "./DeleteMilestoneModal";

export function EditMilestoneForm({
  petId,
  milestoneId,
  date,
}: Readonly<{
  petId: string;
  milestoneId: string;
  date: Date | null;
}>) {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [{ isPending, isSuccess, isError }, { exec: saveMilestone }] =
    usePromise(updateMilestone);

  const { refetchMilestones } = useMilestones();

  const [ dateError, setDateError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isSuccess) {
      showToast({
        description: t(LIFESTAGE.UPDATE_SUCCESS),
        type: "success",
      });
      datalayerPush(GTM_ACTIONS.EDIT_A_MILESTONE_DATE);
      refetchMilestones();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast({
        description: t(LIFESTAGE.UPDATE_FAILED),
        type: "error",
      });
    }
  }, [isError]);

  useEffect(() => { 
    if (dateError) {
      showToast({
        description: dateError,
        type: "error",
      });
    }
  }, [dateError]);

  const handleSubmit = useCallback<
    Config<{ completedDate: string }>["onSubmit"]
  >(
    function handleSubmit(values) {
      const enteredDate = parse(values.completedDate, "yyyy-MM-dd", new Date());  
      if (isValid(enteredDate) && isAfter(enteredDate, new Date())) {  
        setDateError(t(LIFESTAGE.VALID_DATE, "") as string); 
      } else {  
        setDateError(undefined);  
        saveMilestone({
          petId,
          petMilestoneId: milestoneId,
          completedDate: values.completedDate,
        });
      }
    },
    [saveMilestone, petId, milestoneId, t]
  );

  const initialValue = date ? format(date, "yyyy-MM-dd") : "";

  return (
    <Form
      onSubmit={handleSubmit}
      render={function ({ handleSubmit, pristine, values }) {
        const isValidDate = isValid(
          parse(values.completedDate || "", "yyyy-MM-dd", new Date())
        );

        return (
          <form onSubmit={handleSubmit}>
            <label className="label2 md:label1">
              <span className="mb-2">{t(LIFESTAGE.DATE_COMPLETED)}</span>
              <Field name="completedDate" initialValue={initialValue}>
                {({ input }) => <DateInput {...input} showCalendar max={new Date()} />}
              </Field>
            </label>
            <Modal.Footer className="md:flex-row-reverse md:justify-start">
              <Button
                classes="lg:label1 max-md:w-full md:min-w-[175px]"
                disabled={
                  pristine ||
                  isPending ||
                  !isValidDate ||
                  initialValue === values.completedDate
                }
              >
                {t(SAVE)}
              </Button>
              <Modal.Root>
                <Modal.Trigger asChild>
                  <Button
                    type="button"
                    variant="dangerOutlined"
                    classes="lg:label1 max-md:w-full"
                    disabled={isPending}
                  >
                    {t(LIFESTAGE.DELETE)}
                  </Button>
                </Modal.Trigger>
                <DeleteMilestoneModal petId={petId} milestoneId={milestoneId} />
              </Modal.Root>
            </Modal.Footer>
          </form>
        );
      }}
    />
  );
}
