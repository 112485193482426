import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, useAuth } from "./useAuth";

function useRequireAuth(redirectUrl = "/signup") {
  const auth: Auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.checkSessionStatus?.();
  }, [auth, navigate, redirectUrl]);
  return auth;
}

export default useRequireAuth;
