import { isDate } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "ui-2";
import { formatMilestoneDate } from "utils";
import { useToastConfig } from "../../../../components/toast";
import { MY_PETS } from "../../../../constants/myPets";
import { LifestageSwiper } from "../LifestageSwiper";
import { CompletedMilestoneItem } from "./CompletedMilestoneItem";
import { MilestonesProvider, useMilestones } from "./context";
import { Empty } from "./Empty";
import { UncompletedMilestoneItem } from "./UncompletedMilestoneItem";

function Milestones({
  petType,
  petId,
  lifestageId,
  petName,
}: Readonly<{
  petType: string;
  petId: string;
  lifestageId: number;
  petName: string;
}>) {
  return (
    <MilestonesProvider
      petId={petId}
      petType={petType}
      initialLifestageId={lifestageId}
    >
      <MilestonesComponent petName={petName} petId={petId} />
    </MilestonesProvider>
  );
}

function MilestonesComponent({
  petName,
  petId,
}: Readonly<{
  petName: string;
  petId: string;
}>) {
  const { t } = useTranslation();
  const {
    isPending,
    isSuccess,
    isError,
    isSettled,
    currentMilestones,
    lifestageIdx,
    setLifestageIdx,
    lifestageLabels,
    activeLifestageLabel,
  } = useMilestones();
  const { showToast } = useToastConfig();

  React.useEffect(() => {
    if (isError) {
      showToast({
        description: "Failed to load milestones",
        status: "warning",
      });
    }
  }, [isError]);

  const isUncompletedMilestonesEmpty =
    isSettled && !currentMilestones?.milestones?.some((m) => !m.completedDate);
  const isCompletedMilestonesEmpty =
    isSettled &&
    !currentMilestones?.milestones?.some((m) => Boolean(m.completedDate));

  return (
    <div className="w-full">
      <LifestageSwiper
        lifestageIdx={lifestageIdx}
        setLifestageIdx={setLifestageIdx}
        lifestageLabels={lifestageLabels}
      />
      <div>
        <h2 className="label2 md:label1 mb-4 text-brand-color-library-gray-800">
          {t(MY_PETS.MILESTONES.UPCOMING_MILESTONES)}
        </h2>
        {isPending && <Loader />}
        {isUncompletedMilestonesEmpty && (
          <Empty>
            {t(MY_PETS.MILESTONES.NO_MILESTONES, {
              petName,
              lifestage: activeLifestageLabel,
            })}
          </Empty>
        )}

        {isSuccess && (
          <ul className="mb-8 flex flex-col gap-2">
            {currentMilestones?.milestones
              .filter((m) => !m.completedDate)
              .map((milestone) => {
                const estimatedDate = isDate(milestone.suggestedDates.from)
                  ? formatMilestoneDate(milestone.suggestedDates.from as Date)
                  : "";

                return (
                  <li key={milestone.petMilestoneId}>
                    <UncompletedMilestoneItem
                      id={String(milestone.petMilestoneId)}
                      petId={petId}
                      name={t(`lifestages.${milestone.petMilestoneName}`)}
                      description={t(
                        `milestoneHelperText.${milestone.petMilestoneHelperText}`
                      )}
                      estimatedDate={estimatedDate}
                    />
                  </li>
                );
              })}
          </ul>
        )}
      </div>

      <div>
        <h2 className="label2 md:label1 mb-4 text-brand-color-library-gray-800">
          {t(MY_PETS.MILESTONES.COMPLETED_MILESTONES)}
        </h2>
        {isPending && <Loader />}
        {isCompletedMilestonesEmpty && (
          <Empty>
            {t(MY_PETS.MILESTONES.NO_MILESTONES, {
              petName,
              lifestage: activeLifestageLabel,
            })}
          </Empty>
        )}

        {isSuccess && (
          <ul className="flex flex-col gap-2">
            {currentMilestones?.milestones
              .filter((m) => Boolean(m.completedDate))
              .map((milestone) => (
                <li key={milestone.petMilestoneId}>
                  <CompletedMilestoneItem
                    id={String(milestone.petMilestoneId)}
                    petId={petId}
                    title={t(`lifestages.${milestone.petMilestoneName}`)}
                    date={milestone.completedDate}
                    description={t(
                      `milestoneHelperText.${milestone.petMilestoneHelperText}`
                    )}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Milestones;
