export const LIFESTAGE = {
  COMPLETE_MILESTONE: "lifestage.completeMilestone",
  MARK_AS_COMPLETE: "lifestage.markAsComplete",
  DATE_COMPLETED: "lifestage.dateCompleted",
  UPDATE_SUCCESS: "lifestageMilestone.updateSuccess",
  UPDATE_FAILED: "common.updateFailed",
  EDIT_MILESTONE: "lifestage.editMilestone",
  DELETE: "lifestage.delete",
  DELETE_SUCCESS: "lifestage.deleteSuccess",
  DELETE_WARNING: "lifestage.deleteWarning",
  DELETE_DESCRIPTION: "lifestage.deleteDescription",
  VALID_DATE: "lifestage.dateError"
};
