import { Area } from "react-easy-crop";

export enum PetSizeEnum {
  Small = 1,
  Medium = 2,
  Large = 3,
}

export enum PetTypesEnum {
  Dog = 1,
  Cat = 2,
}

export enum PetGenderEnum {
  Male = 1,
  Female = 2,
}

export interface PetSizeType {
  petSizeId: PetSizeEnum;
  petTypeId: PetTypesEnum;
  petSizeName: string;
}

export interface PetTypes {
  petTypeId: PetTypesEnum;
  petTypeName: string;
}

export interface PetGender {
  petGenderId: PetGenderEnum;
  petGenderName: string;
}

export interface PetData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageToUpload?: any;
  petImage?: {
    file: File;
    rendition: Area;
  };
  petId?: string;
  petName: string;
  petNickName: string;
  petBreedId: number | null;
  petSizeId: number;
  petBirthdateTypeId: number;
  petBirthday: string;
  petAdoptDate: string;
  petGenderId: number;
  petTypeId: string;
}

export interface AddPetRequest {
  petData: PetData[];
}
